import React from 'react';
import { Box, VStack, Text, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ChatMessageDeliveryStatus, ListedMessage } from '~src/chat/chat-types';
import moment from 'moment';

export const MessageBox = ({
  message,
  userGuid,
  doctorGuid,
}: {
  message: ListedMessage;
  userGuid: string | undefined;
  doctorGuid: string | null | undefined;
}) => {
  const { t } = useTranslation();
  const { senderName, senderGuid, content, createdTimestamp, status } = message;
  const isDoctorMessage = senderGuid === doctorGuid;
  const isOwnMessage = senderGuid === userGuid;

  const isCurrentPatientMessage = senderGuid === userGuid;
  const patientMessageNotRead =
    isCurrentPatientMessage && status !== ChatMessageDeliveryStatus.Read;
  const formattedCreatedAt =
    createdTimestamp &&
    moment(createdTimestamp).format(
      moment(createdTimestamp).isSame(moment(), 'day') // Checking if it's the same day
        ? 'HH:mm'
        : 'DD.MM.YYYY HH:mm',
    );
  const alignment = isOwnMessage ? 'flex-end' : 'flex-start';

  // System message
  if (!senderGuid)
    return (
      <VStack alignSelf="center" alignItems="center" maxWidth={['90%', '80%']}>
        <Box
          alignSelf={alignment}
          marginBottom="-.1rem"
          fontSize="md"
          fontWeight={700}
          color="primary.500"
          textAlign="center"
        >
          {content}
        </Box>
      </VStack>
    );

  // User message
  return (
    <VStack
      alignSelf={alignment}
      alignItems={alignment}
      maxWidth={['90%', '80%']}
    >
      <Box
        alignSelf={alignment}
        marginBottom="-.1rem"
        fontSize="sm"
        fontWeight={700}
      >
        {isDoctorMessage ? (
          <>
            <Flex flexDirection="row" gap={1.5} mb="4px">
              {senderName} - <Text fontWeight={400}>{formattedCreatedAt}</Text>
            </Flex>
            <Text color="primary.500" data-guid={senderGuid}>
              {content}
            </Text>
          </>
        ) : (
          //}{isOwnMessage &&
          <Box color="#000000">
            <Flex flexDirection="row" gap={1.5} mb="4px">
              {patientMessageNotRead
                ? t('chat.discussion.messageUnreadText')
                : 'Sinä'}
              {' -'}
              <Text fontWeight={400}>{formattedCreatedAt}</Text>
            </Flex>

            <Box
              background="#ebebeb"
              borderRadius="1.5rem"
              padding={2.5}
              wordBreak="break-word"
              data-guid={senderGuid}
            >
              {content}
            </Box>
          </Box>
        )}
      </Box>
    </VStack>
  );
};

export default MessageBox;
