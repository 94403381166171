import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ChatContext, CHATROOM_URL_PATH } from '~src/chat/chat-context';
import { Button, Stack } from '@chakra-ui/react';
import { getSessionStorage } from '~src/utils/storage';
import { ChatStatus } from '~src/chat/chat-types';

const ChatDebugComponent = () => {
  const history = useHistory();
  const {
    setUserInfo,
    sendListRoomsRequestMessage,
    sendEnterRoomRequestMessage,
    chatRooms,
  } = useContext(ChatContext);

  const chatStorage = getSessionStorage('chat');
  const accessToken = chatStorage?.accessToken || null;
  return accessToken ? (
    <Stack gap={4} pb={6}>
      <Button onClick={() => sendListRoomsRequestMessage()}>
        Hae potilaan huoneet
      </Button>
      {chatRooms?.map((room) => (
        <Button
          key={room.roomId}
          onClick={() => {
            history.push(`${CHATROOM_URL_PATH}/${room.roomId}`);

            setUserInfo((prev) => ({ ...prev, status: ChatStatus.LoggedIn }));
            sendEnterRoomRequestMessage({
              roomId: room.roomId,
              startChatroomInitFlow: true,
            });
          }}
        >
          Hyppää huoneeseen: (id: {room.roomId})
        </Button>
      ))}
    </Stack>
  ) : null;
};

export { ChatDebugComponent };
