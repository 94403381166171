import { z } from 'zod';
import {
  ChatErrorCode,
  ChatMessageContentType,
  ChatMessageDeliveryStatus,
  ChatRoomStatus,
  ChatterRole,
  ChatterStatus,
  WebsocketMessageType,
} from './chat-types';

export const websocketMessageTypeSchema = z.nativeEnum(WebsocketMessageType);

export const chatRoomStatusSchema = z.nativeEnum(ChatRoomStatus);

export const chatterRoleSchema = z.nativeEnum(ChatterRole);

export const chatterStatusSchema = z.nativeEnum(ChatterStatus);

export const chatMessageContentTypeSchema = z.nativeEnum(
  ChatMessageContentType,
);

export const chatMessageDeliveryStatusSchema = z.nativeEnum(
  ChatMessageDeliveryStatus,
);

export const chatErrorCodeSchema = z.nativeEnum(ChatErrorCode);

export const authorizationMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  authorizationToken: z.string().optional().nullable(),
});

export const updateRoomStatusMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  roomId: z.string().uuid(),
  status: chatRoomStatusSchema,
});

export const clientStatusUpdateMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  firstAndLastName: z.string().min(1),
  guid: z.string().uuid().optional().nullable(),
  roomId: z.string().uuid().optional().nullable(),
  status: chatterStatusSchema,
});

export const listRoomsFilterSchema = z.object({
  activeWithinHours: z.number(),
  status: chatRoomStatusSchema.optional().nullable(),
});

export const listRoomsRequestMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  filter: listRoomsFilterSchema,
});

export const roomMemberSchema = z.object({
  firstAndLastName: z.string().min(1),
  role: chatterRoleSchema,
  status: chatterStatusSchema,
  guid: z.string().uuid(),
  ssid: z.string().optional().nullable(),
});

export const roomInfoSchema = z.object({
  roomId: z.string().uuid(),
  unreadMessageCount: z.number(),
  created: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  lastActive: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  queueId: z.string().uuid().optional().nullable(),
  patientQueueItemGuid: z.string().uuid(),
  members: z.array(roomMemberSchema),
});

export const roomListMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  rooms: z.array(roomInfoSchema).optional().nullable(),
});

export const newChatMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  messageId: z.string().uuid(),
  contentType: chatMessageContentTypeSchema,
  content: z.string().min(1),
  roomId: z.string().uuid().optional().nullable(),
  createdTimestamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  senderName: z.string().optional().nullable(),
  senderGuid: z.string().uuid().optional().nullable(),
});

export const enterRoomRequestMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  roomId: z.string().uuid(),
});

export const exitRoomRequestMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  roomId: z.string().uuid(),
});

export const getRoomMessagesRequestSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  roomId: z.string().uuid(),
  since: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  take: z.number().optional().nullable(),
  skip: z.number().optional().nullable(),
});

export const listedMessageSchema = z.object({
  messageId: z.string().uuid(),
  contentType: chatMessageContentTypeSchema,
  content: z.string().min(1),
  createdTimestamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    )
    .optional()
    .nullable(),
  senderName: z.string().optional().nullable(),
  senderGuid: z.string().uuid().optional().nullable(),
  status: chatMessageDeliveryStatusSchema,
});

export const roomMessageListMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  roomId: z.string().uuid(),
  messages: z.array(listedMessageSchema).optional().nullable(),
});

export const updateMessageStatusMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  messageId: z.string().uuid(),
  roomId: z.string().uuid().optional().nullable(),
  status: chatMessageDeliveryStatusSchema.optional().nullable(),
});

export const pingMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
});

export const errorMessageSchema = z.object({
  messageType: websocketMessageTypeSchema,
  timeStamp: z
    .string()
    .regex(
      /^(?:19|20)\d{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])T(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d{1,9})?(?:Z|[+-](?:[01]\d|2[0-3]):[0-5]\d)$/,
      'Invalid ISO 8601 date-time format with or without offset',
    ),
  error: z.string().min(1),
  errorCode: chatErrorCodeSchema,
});
