import { useEffect, useCallback, useRef } from 'react';
import { ChatterStatus } from './chat-types';
import { chatLogger } from './chat-utils';

const WRITING_STATUS_DELAY = 3000;

function useTypingStatus(
  roomId: string | null,
  sendClientStatusUpdateMessage: (props: {
    status: ChatterStatus.Online | ChatterStatus.Writing;
    roomId: string;
  }) => void,
) {
  const isTypingRef = useRef<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const startTyping = useCallback(() => {
    if (!roomId) return;

    // Access the ref's current value correctly
    if (!isTypingRef.current) {
      chatLogger('typing started');
      isTypingRef.current = true;
      sendClientStatusUpdateMessage({
        status: ChatterStatus.Writing,
        roomId,
      });
    }

    // Clear the timeout to prevent sending "ChatterStatus.Online" if the user is still typing
    timeoutRef.current && clearTimeout(timeoutRef.current);

    // Set up a timeout to send "ChatterStatus.Online" after WRITING_STATUS_DELAY of inactivity
    timeoutRef.current = setTimeout(() => {
      chatLogger('typing stopped');
      isTypingRef.current = false;
      sendClientStatusUpdateMessage({
        status: ChatterStatus.Online,
        roomId,
      });
    }, WRITING_STATUS_DELAY);
  }, [roomId, sendClientStatusUpdateMessage]);

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      if (!roomId || !isTypingRef.current) return;
      isTypingRef.current = false;
      chatLogger('typing hook unmounts - sending online status');
      sendClientStatusUpdateMessage({
        status: ChatterStatus.Online,
        roomId,
      });
    };
  }, [roomId, sendClientStatusUpdateMessage]);

  return { startTyping };
}

export { useTypingStatus };
