import useWebSocket, { Options } from 'react-use-websocket';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';
import { devlogger } from '../utils';
import { AuthorizationMessage, WebsocketMessageType } from './chat-types';
import { WebsocketMessage } from './chat-utils';
import { useChatTokens } from './useChatTokens';

const websocketDefaultConfigs = {
  share: true,
  // 10 minutes of reconnect retries
  reconnectAttempts: 100,
  reconnectInterval: 6000,
  onMessage: (event: MessageEvent<string>) => {
    devlogger('[WEBSOCKET]: Websocket message received', event);
  },
};

export enum WebsocketConnectionName {
  Chat = 'Chat',
  Default = 'Default',
}

export type DuodecimWebsocketCustomParams = {
  afterOnOpen?: () => void;
};

function useDuodecimWebsocket(
  connectionName: WebsocketConnectionName = WebsocketConnectionName.Default,
  options?: Options | undefined,
  customParams: DuodecimWebsocketCustomParams = {},
  //connect?: boolean | undefined,
): WebSocketHook<unknown, MessageEvent<WebsocketMessage> | null> {
  const { afterOnOpen } = customParams;
  const { accessToken } = useChatTokens();

  // Establish the websocket connection(s) only if the user is logged in and user info is fetched
  const url = `${process.env.NEXT_PUBLIC_WEBSOCKET_URL}${
    connectionName === WebsocketConnectionName.Chat ? '/Chat' : ''
  }`;
  //chatLogger('useDuodecimWebsocket accessToken', accessToken, url);

  const { sendMessage, readyState, ...restWebsocketData } = useWebSocket(
    accessToken ? url : null,
    {
      ...websocketDefaultConfigs,
      shouldReconnect: (closeEvent) => {
        devlogger(
          `[${connectionName}]: should ${
            accessToken ? '' : 'NOT'
          } reconnect - Websocket close event:`,
          closeEvent,
        );
        return !!accessToken;
      },
      onOpen(event: Event) {
        if (!accessToken) return;
        devlogger('opOpen event', event);
        const authMessage: AuthorizationMessage = {
          messageType: WebsocketMessageType.AuthorizationMessage,
          timeStamp: new Date().toISOString(),
          authorizationToken: accessToken,
        };
        devlogger(
          `[${connectionName}]: Websocket onOpen callback`,
          authMessage,
        );
        sendMessage(JSON.stringify(authMessage));
        afterOnOpen && afterOnOpen();
      },
      ...options,
    },
  );
  return { ...restWebsocketData, readyState, sendMessage };
}

export { useDuodecimWebsocket };
