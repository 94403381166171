import React from 'react';

import Discussion from '~/src/components/Chat/Discussion';
import ChatHeader from '~/src/components/Chat/ChatHeader';
import { mainContentId } from '~/src/constants';

const ChatDiscussion: React.FC = () => {
  return (
    <>
      <ChatHeader />
      <main id={mainContentId} style={{ marginBottom: -32 }}>
        <Discussion />
      </main>
    </>
  );
};

export default ChatDiscussion;
