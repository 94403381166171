import { Box, Flex } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ChatContext } from '~src/chat/chat-context';
import { ChatterRole, ChatterStatus } from '~src/chat/chat-types';
import { HourGlassIcon } from '~src/components/KuuraIcon/icons/HourGlassIcon';
import { UserIcon } from '~src/components/KuuraIcon/icons/UserIcon';
import { DoctorOfflineModal } from './DoctorOfflineModal';

const DoctorChatStatus = () => {
  const { chatRooms, activeRoomId } = useContext(ChatContext);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [showDoctorOfflineModal, setShowDoctorOfflineModal] =
    useState<boolean>(false);

  const activeRoom = chatRooms.find((room) => room.roomId === activeRoomId);
  const doctorData = activeRoom?.members.find(
    (member) => member.role === ChatterRole.Doctor,
  );
  const isDoctorOnline = doctorData?.status === ChatterStatus.Online;

  useEffect(() => {
    if (!isDoctorOnline) {
      timeoutRef.current = setTimeout(() => {
        setShowDoctorOfflineModal(true);
      }, 60000);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [isDoctorOnline]);

  if (!activeRoomId) return <></>;

  return (
    <Flex
      flexDirection="row"
      alignContent="center"
      gap={2}
      h="auto"
      alignItems="center"
    >
      <DoctorOfflineModal
        isOpen={showDoctorOfflineModal}
        onClose={() => setShowDoctorOfflineModal(false)}
      />
      {!doctorData && (
        <>
          <HourGlassIcon width={6} height={6} color="primary.500" />
          <Box color="primary.500" fontSize="md" fontWeight={700}>
            Olet jonossa
          </Box>
        </>
      )}
      {doctorData && (
        <>
          <UserIcon width={6} height={6} color="primary.500" />
          <Flex flexDirection="column" gap={0} pt={2}>
            <Box
              color="primary.500"
              fontSize="md"
              fontWeight={700}
              lineHeight="20px"
            >
              {doctorData.firstAndLastName}
            </Box>
            <Flex
              color="white"
              fontSize="10px"
              fontWeight={700}
              textAlign="center"
              bg={isDoctorOnline ? '#00B027' : '#8298A5'}
              borderRadius={16}
              px="5px"
              justifyContent="center"
              alignItems="center"
              w="fit-content"
              cursor="pointer"
              onClick={() => setShowDoctorOfflineModal(true)}
            >
              {isDoctorOnline ? 'PAIKALLA' : 'EI PAIKALLA'}
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export { DoctorChatStatus };
