import React from 'react';
import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Container from '~/src/components/Container';
import ChatInit from '~/src/components/Chat/ChatInit';

const Chat = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Heading
          as="h1"
          color="primary.500"
          fontSize={['2rem', null, '4xl']}
          lineHeight="125%"
          mt={8}
          mb={8}
          textAlign="center"
        >
          {t('chat.intro.title')}
        </Heading>
        <ChatInit marginTop={12} />
      </Container>
    </>
  );
};

export default Chat;
