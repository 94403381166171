import React, { useContext, useRef } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  Heading,
  AlertDialogBody,
  AlertDialogFooter,
  HStack,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { LogOutIcon } from '~src/components/KuuraIcon/icons/LogOutIcon';
import { ChatContext, CHAT_LOGIN_URL_PATH } from '~src/chat/chat-context';

const EndDiscussion: React.FC = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef: React.RefObject<any> = useRef();
  const history = useHistory();
  const { activeRoomId, clearChatContext } = useContext(ChatContext);

  return (
    <>
      <IconButton
        onClick={onOpen}
        variant="link"
        padding={2}
        icon={<LogOutIcon width={6} height={6} color="primary.500" />}
        aria-label={t('chat.discussion.endDiscussion')}
      />

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
      >
        <AlertDialogOverlay />

        <AlertDialogContent
          borderRadius="2rem"
          marginX={4}
          padding={8}
          textAlign="center"
          maxWidth={['calc(100% - 2rem)', '28rem']}
        >
          <AlertDialogHeader
            as={Heading}
            fontWeight="bold"
            padding={0}
            size="sm"
          >
            {t('chat.discussion.endDiscussionAlert.title')}
          </AlertDialogHeader>

          <AlertDialogBody as="p" paddingBottom={8} paddingTop={4}>
            {t('chat.discussion.endDiscussionAlert.paragraph')}
            <br />
            <br />
            {t('chat.discussion.endDiscussionAlert.paragraph1')}
          </AlertDialogBody>

          <AlertDialogFooter as={HStack} padding={0} justifyContent="center">
            <Button
              ref={cancelRef}
              onClick={onClose}
              minWidth={['8rem', '10rem']}
            >
              {t('chat.discussion.endDiscussionAlert.backButton')}
            </Button>

            <Button
              onClick={() => {
                const roomId = activeRoomId; // copy roomId before clearing context
                clearChatContext();
                setTimeout(() => {
                  return history.push(CHAT_LOGIN_URL_PATH + '/' + roomId);
                }, 400);
              }}
              minWidth={['8rem', '10rem']}
              width="auto"
            >
              {t('chat.discussion.endDiscussionAlert.proceedButton')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default EndDiscussion;
