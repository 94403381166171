import React, { useContext, useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  FlexProps,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Card, { CardContentStack } from '~/src/components/Card';
import { ChatDebugComponent } from './ChatDebugComponent';
import { useChatTokens } from '~src/chat/useChatTokens';
import { ChatContext } from '~src/chat/chat-context';
import { chatLogger } from '~src/chat/chat-utils';

const ChatInit: React.FC<FlexProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const { setUserInfo } = useContext(ChatContext);
  const {
    createGuestSession,
    createGuestSessionParams: { loading },
  } = useChatTokens();
  const [chatInitPayload, setChatInitPayload] = useState({
    ssid: '',
    firstName: '',
    lastName: '',
  });

  useEffect(
    () => setUserInfo((prev) => ({ ...prev, ...chatInitPayload })),
    [chatInitPayload, setUserInfo],
  );

  const { ssid, firstName, lastName } = chatInitPayload;
  return (
    <Card {...props}>
      <CardContentStack
        onSubmit={(event) => {
          event.preventDefault();
          chatLogger('ChatInit', 'createGuestSession', chatInitPayload);
          void createGuestSession({
            variables: { body: chatInitPayload },
          });
        }}
        as="form"
        maxWidth="23rem"
      >
        <FormControl aria-label={t('chat.init.inputLabel') || ''} id="ssid">
          <FormLabel textAlign="center">Hetu</FormLabel>

          <Input
            type="text"
            value={ssid}
            onChange={(e) =>
              setChatInitPayload((prev) => ({
                ...prev,
                ssid: e.target.value,
              }))
            }
            placeholder={'112233-456A'}
            textAlign="center"
            autoComplete="off"
          />
          <FormLabel textAlign="center" pt={4}>
            Etunimi
          </FormLabel>
        </FormControl>

        <FormControl aria-label={t('chat.init.inputLabel') || ''} id="Etunimi">
          <Input
            type="text"
            value={firstName}
            onChange={(e) =>
              setChatInitPayload((prev) => ({
                ...prev,
                firstName: e.target.value,
              }))
            }
            placeholder={'Etunimi'}
            textAlign="center"
            autoComplete="off"
          />

          <FormLabel textAlign="center" pt={4}>
            Sukunimi
          </FormLabel>
        </FormControl>

        <FormControl aria-label={t('chat.init.inputLabel') || ''} id="Sukunimi">
          <Input
            type="text"
            value={lastName}
            onChange={(e) =>
              setChatInitPayload((prev) => ({
                ...prev,
                lastName: e.target.value,
              }))
            }
            placeholder={'Sukunimi'}
            textAlign="center"
            autoComplete="off"
          />
        </FormControl>

        <Button
          type="submit"
          isDisabled={!firstName || !ssid || !lastName}
          isLoading={loading}
          minW="20rem"
          width="100%"
        >
          {t('chat.init.buttonLabel')}
        </Button>
      </CardContentStack>
      <ChatDebugComponent />
    </Card>
  );
};

export default ChatInit;
