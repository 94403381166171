import { useEffect, useRef, useState } from 'react';
import { ListedMessage } from './chat-types';

const useChatScroll = (
  messages: ListedMessage[],
  isPatientWriting: boolean,
  activeRoomId: string | null,
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [initialScrollDone, setInitialScrollDone] = useState<boolean>(false);

  const scrollToBottom = () =>
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });

  const setScrollToBottom = () =>
    containerRef.current?.scrollTo(0, containerRef.current.scrollHeight);

  const isNearBottom = () => {
    if (!containerRef.current) return false;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    return scrollHeight - scrollTop - clientHeight <= 50; // Check if within 30px of the bottom
  };
  useEffect(() => {
    if (activeRoomId && messages.length > 1) {
      setScrollToBottom();
    } else if (activeRoomId) setInitialScrollDone(false);
  }, [activeRoomId, messages.length]);

  useEffect(() => {
    if (isNearBottom() || (!initialScrollDone && messages.length > 1)) {
      setTimeout(() => scrollToBottom(), 100);
      setInitialScrollDone(true);
    }
  }, [messages, isPatientWriting, initialScrollDone]);

  return { containerRef, messagesEndRef };
};

export default useChatScroll;
