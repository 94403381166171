import React, { Dispatch, SetStateAction, createContext } from 'react';
import {
  ChatMessageDeliveryStatus,
  ChatterStatus,
  ListRoomsFilter,
  ListedMessage,
  RoomInfo,
  ChatUser,
  ChatStatus,
  ChatSettings,
} from './chat-types';
import { useChat } from './useChat';
import { WebsocketMessage } from './chat-utils';
import { chat } from '~src/configurations';

export const CHAT_LOGIN_URL_PATH = '/chat/login';

export const CHAT_LOGIN_URL = [
  CHAT_LOGIN_URL_PATH,
  CHAT_LOGIN_URL_PATH + '/:chatRoomId',
];
export const CHATROOM_URL_PATH = '/chat/room';

export const CHAT_LOGGER_ENABLED = true;

export const PING_INTERVAL = 30000; // 30 seconds
export const PING_TIMEOUT = 30000; // 30 seconds

export const defaultChatUser: ChatUser = {
  firstName: '', // set after 2FA
  lastName: '', // set after 2FA
  ssid: '', // set after 2FA
  userGuid: '', // set after AuthorizationMessage response
  status: ChatStatus.LoggedOut,
};

export const defaultChatSettings: ChatSettings = {};

export type ChatContextType = {
  userInfo: ChatUser;
  setUserInfo: Dispatch<SetStateAction<ChatUser>>;
  chatSettings: ChatSettings;
  upsertChatSettings: (
    settings: Partial<ChatContextType['chatSettings']>,
  ) => void;
  chatRooms: RoomInfo[];
  setChatRooms: Dispatch<SetStateAction<RoomInfo[]>>;
  getChatRoomById: (props: { roomId: string }) => RoomInfo | null;
  activeRoomId: string | null;
  setActiveRoom: (props: { roomId: string | null }) => void;
  getRoomMessages: (props: { roomId: string }) => ListedMessage[];
  roomsMessages: Record<string, ListedMessage[]>; // probably enough if state is in only inside useChat -hook
  roomsMessagesLoaded: Record<string, string>;
  sendWsMessage: (props: { message: WebsocketMessage }) => void; // debug purposes
  sendNewChatMessage: (
    message: string,
    roomId: string,
    senderName: string,
  ) => void;
  sendUpdateMessageStatusMessage: (props: {
    roomId: string;
    messageId: string;
    status: ChatMessageDeliveryStatus;
  }) => void;
  sendClientStatusUpdateMessage: (props: {
    status: ChatterStatus;
    roomId: string;
  }) => void;
  sendListRoomsRequestMessage: (props?: { filter: ListRoomsFilter }) => void;
  startTyping: () => void;
  sendExitRoomRequestMessage: (props: { roomId: string }) => void;
  sendEnterRoomRequestMessage: (props: {
    roomId: string;
    startChatroomInitFlow: boolean;
  }) => void;
  clearChatContext: () => void;
};

export const ChatContext = createContext<ChatContextType>({
  userInfo: defaultChatUser,
  setUserInfo: () => {},
  chatSettings: defaultChatSettings,
  upsertChatSettings: () => {},
  chatRooms: [],
  getChatRoomById: () => null,
  setChatRooms: () => {},
  activeRoomId: null,
  setActiveRoom: () => {},
  getRoomMessages: () => [],
  roomsMessages: {},
  roomsMessagesLoaded: {},
  startTyping: () => {},
  clearChatContext: () => {},
  //-------- websocket functions ------ //
  sendWsMessage: () => {},
  sendUpdateMessageStatusMessage: () => {},
  sendNewChatMessage: () => {},
  sendClientStatusUpdateMessage: () => {},
  sendEnterRoomRequestMessage: () => {},
  sendExitRoomRequestMessage: () => {},
  sendListRoomsRequestMessage: () => {},
});

function ChatProvider({ children }: { children: React.ReactNode }) {
  const chatHookData = useChat();
  return (
    <ChatContext.Provider value={{ ...chatHookData }}>
      {children}
    </ChatContext.Provider>
  );
}

export const ChatProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    (chat.enableChat && <ChatProvider>{children}</ChatProvider>) || (
      <>{children}</>
    )
  );
};
